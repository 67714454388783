.tab{
    text-align: center;
    margin-top:50px;
}

.tab ul{
list-style-type: none;
}

.tab ul li.flex-fill {
    display: inline;
    color: #fff;
    text-align: center;
    background: #123b93b8;
    padding: 30px;
    margin: 10px;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
}

.tab ul li.flex-fill:hover{
color:#fff;
cursor: pointer;;
background: #090097;
}

.tab ul li.flex-fill:active,
.tab ul li.flex-fill:visited{
    color:#fff ! important;
    cursor: pointer;;
    background: #033051 !important;
    }

    .updateToggle{
    background:red;
    }

.content{
display:none;
}

.show-content{
display:block;
}

@media screen and (max-width:767px) {
    .tab ul li.flex-fill{
    display:block !important;
    }

    .content{
        display:block;
        }
    
}