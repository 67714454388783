.project1 img{
position: relative !important;
  left: 50% !important;
  /*transform: translate3d(-50%, -69.3844px, 0px);
  transform-style: preserve-3d;
  backface-visibility: hidden;*/

  filter: none;
  width: 60% !important;
  height:auto !important;
}

.project2 img{
  position: relative !important;
    left: 10% !important;
    /*transform: translate3d(-50%, -69.3844px, 0px);
    transform-style: preserve-3d;
    backface-visibility: hidden;*/
  
    filter: none;
    width: 60% !important;
    height:auto !important;
  }

.proinfo1{
  
  position: relative;
  background: #222;
  top: 78%;
  /*transform: translate(-50%, -50%);*/
  width: 100%;
  height: 200px;
  
}

.proinfo2{
  
  position: relative;
  background: #222;
  top: 0%;
  /*transform: translate(-50%, -50%);*/
  width: 50%;
  height: 200px;
  left:75%;
 
}

.react-parallax{
  margin-bottom:30px;
}
.project-spacer{
  height:50px;
  display: block;
  width: 100%;
}

.project-more1 a,
.project-more2 a,
.project-more3 a,
.project-more4 a{
  display: block;
  border: 1px solid #fff;
  color: #fff;
  padding: 10px;

}

.project-more1,
.project-more3{
  margin-left: 5%;
  width: 30%;
}

.project-more2,
.project-more4{
  margin-left: 4%;
  width: 30%;
}