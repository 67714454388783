.welcome-col-left h1,
.welcome-col-left h2,
.col1 h1,
.col4 h1,
.col1 h5,
.col4 h5,
.Project-Container h1,
.welcome-col h1,
.welcome-col h2,
.pro-pl-col-left1 h1,
.pro-pl-col-left1 h2,
.pro-pl-col-right2 h1,
.pro-pl-col-right2 h2,
.pro-col-col-top h1,
.pro-col-col-top h2 {
    line-height: 1.2;

}

.emph{
    font-weight: 600;
    font-style: normal;
}

.emph-hm{
    font-weight: 300;
  font-style: normal;
  font-size: 60px;

  width: 40%;
  display: block;
  margin: auto;
  color:#222;
}

.col1 h1,
.col4 h1,
.col1 h5,
.col4 h5,
.note p,
.pro-col-col h4,
.pro-col-col p {
    color: #fff ! important;
}

.acu-col-right h1{
    color:#fff;
}

.welcome-copy-project1 h1,
.welcome-copy-project1 h3,
.welcome-copy-project2 h1,
.welcome-copy-project2 h3,
.welcome-copy-project3 h1,
.welcome-copy-project3 h3,
.welcome-copy-project4 h1,
.welcome-copy-project4 h3,
.project-info-box1 h1,
.project-info-box1 h3,
.project-info-box2 h1,
.project-info-box2 h3{
    color:#fff !important;
    text-align: left;
    font-weight:300;
   
    padding:0 30px;
    text-align: left !important;
    line-height: 1 !important;
}

.welcome-copy-project1 h1,
.welcome-copy-project1 h3,
.welcome-copy-project3 h1,
.welcome-copy-project3 h3{
    margin-left:50%;
}

.welcome-copy-project2 h1,
.welcome-copy-project2 h3,
.welcome-copy-project4 h1,
.welcome-copy-project4 h3{
    margin-left:0%;
}

.welcome-copy-project1 h1,
.welcome-copy-project2 h1,
.welcome-copy-project3 h1,
.welcome-copy-project4 h1,
.project-info-box1 h1,
.project-info-box2 h1{
    font-size: 32px !important
}
.welcome-copy-project1 h3,
.welcome-copy-project2 h3,
.welcome-copy-project3 h3,
.welcome-copy-project4 h3,
.project-info-box1 h3,
.project-info-box2 h3{
    font-size: 22px !important
}
.welcome-copy-project1 h1 em,
.welcome-copy-project1 h3 em,
.welcome-copy-project2 h1 em,
.welcome-copy-project2 h3 em,
.welcome-copy-project3 h1 em,
.welcome-copy-project3 h3 em,
.welcome-copy-project4 h1 em,
.welcome-copy-project4 h3 em,
.project-info-box1 h1 em,
.project-info-box1 h3 em,
.project-info-box2 h1 em,
.project-info-box3 h3 em{
 font-weight:800;
 font-style: normal;   
}

.IndicatorCarouselText h1,
.blue-bar h4,
.Gal-info h2,
.ft-col-left h1,
.pro-pl-col h1,
.acu-col-top-cont h1,
.acu-col-top-contact h3 {
    color:#222 !important;;
}

.acu-col-right-abt h1{
    color:#fff !important;
}


.acu-col-right-abt2 h1,
.acu-col-top-res h1{
color:#383838;
}

.acu-col-right-abt2 h5,
.formInput2 h5{
    text-align: left;
  padding: 0;
  color: #222;
  font-weight: 400;
  font-size: 18px;
  margin:0 0 10px 0;
}


.acu-col-right h2,
.Gal-info p,
.blue-bar p,
.acu-col-right-abt h2,
.acu-col-top-cont h2,
.acu-col-top-contact h2 {
    color: #222 !important;
}


.IndicatorCarouselText h1,
.welcome-col-left h1,
.acu-col-right h1,
.best-sellers h1,
.best-sellers-home h1,
.inner_body h1,
.banner_body h1,
.ft-col-left h1,
.acu-col-right-abt h1,
.acu-col-right-abt2 h1,
.welcome-col h1,
.inner_body-colour h1,
.acu-col-top-cont h1,
.acu-col-top-res h1,
.welcome-col-product h1,
.acu-col-top-contact h1 {
    font-size: 40px;
    font-weight: 200;
    
}

.welcome-col-product h2{
color:#113c94;
padding:30px 0 20px 0;
font-size:40px;
font-weight: 600;
margin-top:70px;
}

.pro-pl-col-left1 h1,
.pro-pl-col-right2 h1,
.pro-pl-col-top h1{
    font-size:22px;
    font-weight: 400;
}

.banner_body h1{
    color:#090097;
}



.IndicatorCarouselText h1 {
    text-align: center;

    padding: 80px 0 35px !important;

    font-weight: 400;
    margin     : 0 !important;

}

.IndicatorCarouselText em {
    font-style    : normal;
    text-transform: uppercase;
    font-weight   : 600;
}

.acu-col-right-abt2 h1{
    text-align: left;
    font-weight: 200;
}

.welcome-col-left h1,
.acu-col-right h1,
.Project-Container h1,
.ft-col-left h1,
.acu-col-right-abt h1,
.acu-col-right-abt2 h1,
.welcome-col h1,
.pro-pl-col-left1 h1,
.pro-pl-col-right2 h1,
.pro-pl-col-top h1,
.acu-col-top-cont h1,
.acu-col-top-res h1,
.acu-col-top-contact h1{

    line-height: 1.4;
}

.acu-col-right-abt h1,
.acu-col-right-abt h3,

.acu-col-top-cont h1,
.acu-col-top-res h1,
.acu-col-top-contact h1{
    text-align: center;
}

.pro-pl-col h3{
    text-align: left;
}


.welcome-col-left h1,
.welcome-col-left h3,
.acu-col-right h1,
.acu-col-right h3,
.Project-Container,
.ft-col-left h1,
.ft-col-left h3,
.welcome-col h1,
.welcome-col h3,
.pro-pl-col-left1 h1,
.pro-pl-col-left1 h3,
.pro-pl-col-right2 h1,
.pro-pl-col-right2 h3,
.acu-col-right-abt2 h3,
.pro-pl-col-top h1,
.pro-pl-col-top h3,
.welcome-col-pro1 h3 {

    text-align: left;

}

.col1 h1 {
    text-align: left;
    padding   : 70px 0 0 30px;
}

.col4 h1 {
    text-align  : left;
    /*padding   : 30px 100px 0 30px;*/
    padding     : 70px 100px 0 30px;
}

.col1 h5,
.col4 h5 {
    font-weight: 400;
    text-align : left;
    padding    : 0 100px 0 30px;
    font-size  : 16px;
}


.welcome-col h1,
.welcome-col-left h1,
.col1 h1,
.col4 h1,
.acu-col-right h1,
.Project-Container h1,
.ft-col-left h1,
.acu-col-right-abt h1,
.acu-col-right-abt2 h3,
.pro-pl-col-left1 h1,
.pro-pl-col-left1 h3,
.pro-pl-col-right2 h1,
.pro-pl-col-right2 h3,
.pro-pl-col-top h1,
.pro-pl-col-top h3,
.acu-col-top-cont h1,
.acu-col-top-res h1,
.acu-col-top-contact h1 {

    font-weight: 200;

}

.col1 h1,
.col4 h1 {
    font-size: 24px;
}

.acu-col-right-abt h3,
.acu-col-right-abt2 h3{
color:#222;
}

.ft-col-left h3{
color:#000;
}


.acu-col-right h3,
.acu-col-right-abt h3,
.pro-pl-col h3{
    color:#fff;
}

.acu-col-top-cont h3,
.contact-form-container h5,
.acu-col-top-contact h3{
    color:#222 !important;
}

.welcome-col h3,
.welcome-col-left h3,
.acu-col-right h3,
.ft-col-left h3,
.acu-col-right-abt h3,
.acu-col-right-abt2 h3,
.pro-pl-col h3,
.acu-col-top-cont h3,
.welcome-col-product h3,
.welcome-col-pro1 h3,
.acu-col-top-contact h3 {
    /*font-size:26px;*/
    font-size     : 22px;
    font-weight   : 300;
    padding       : 0;
    line-height   : 1.6;
    padding-bottom: 15px;

}


.pro-pl-col-left1 h3,
.pro-pl-col-right2 h3,
.pro-pl-col-top h3{
    font-size     : 18px;
    font-weight   : 300;
    padding       : 0;
    line-height   : 1.6;
    padding-bottom: 15px;
}

.project-copy h2{
    color:#000000;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
}

.welcome-col h1{
color:#d4bc9d !important;
}

.welcome-col h3{
    color:#fff !important;
    }
    
.Project-Container h1,
.welcome-col-left h1,
.welcome-col-left h3,
.welcome-col-product h1,
.welcome-col-product h3,
.welcome-col-pro1 h3{
color:#222;
}

.pro-pl-col-left1 h1,
.pro-pl-col-left1 h3,
.pro-pl-col-right2 h1,
.pro-pl-col-right2 h3,
.pro-pl-col-top h1,
.pro-pl-col-top h3{
    color:#222;
}

.best-sellers h1,
.best-sellers h4,
.best-sellers-home h1,
.best-sellers-home h4 {
    text-align  : center;
    /*color     : #404040 ! important;*/
}

.best-sellers h1,
.best-sellers-home h1,
.inner_body h1,
.banner_body h1{
    text-align    : center;
    color:#000;
}

.inner_body-color h1{
text-align: left;
}

.banner_body h1{
padding:30px 0 50px 0;
}

.best-sellers h1,
.best-sellers-home h1,
.inner_body h1,
.inner_body-color h1 {
    font-weight   : 200;
   
    padding-bottom: 0 ! important;
}

.inner_body h2,
.inner_body-colour h2 {
    font-size    : 24px;
    /*font-size  : 18px;*/
    text-align   : left;
    font-weight  : 400;
    /*color      : #404040 ! important;*/

}

.inner_body h4,
.inner_body-colour h4 {
    font-size  : 24px;
    text-align : left;
    font-weight: 400;


}

.inner_body h5,
.inner_body-color h5 {
    font-size  : 16px;
    text-align : left;
    font-weight: 800;

}

.best-sellers h4,
.best-sellers-home h4,
.inner_body h3{
text-align: center;
}

.inner_body-colour h3 {
text-align: left;
}

.best-sellers h4,
.best-sellers-home h4,
.inner_body h3,
.inner_body-colour h3 {
    font-size  : 22px;
    font-weight: 300;
 
    padding-top: 0 ! important;
    line-height: 1.5;
}


.blue-bar h4 {
    text-align: center;
    padding   : 30px 30px 0;
}

.Gal-info h2 {
    text-align: left;
}

.blue-bar h4,
.Gal-info h2 {
    display: block;





    font-size  : 30px;
    font-weight: 400 ! important;
    margin     : 0;
    padding    : 20px 30px 0 30px;
}

.Gal-info p {
    padding: 0 30px 0 30px;

    line-height: 1.7;
}


.blue-bar p {

    line-height: 1.5;
    padding    : 0 80px 0;
}

.inner_body p,
.inner_body-colour p {

    line-height: 1.7;
}

.inner_body ul li,
.inner_body-colour ul li {

    list-style-position: inherit;
    line-height        : 1.6;

    margin-left: 30px;
}

.graphene_tit ul li {
    width: 50%;
}

.inner_body h6,
.inner_body-colour h6 {
    color: transparent ! important;
}

.note p {

    text-align: center ! important;
    font-size : 14px;

    line-height: 1.7;
}




h3.ft-col-tit {
    margin        : 0 0 20px 0 ! important;
    text-transform: uppercase;

    text-align : left;
    font-weight: bolder;
    color:#fff;

}

p.graphene-copy {
    width: 50%;
}


.pro-col-col h4 {

    padding    : 130px 100px 0;
    font-size  : 30px;
    font-weight: 400;
    margin     : 0
}


.pro-col-col p {

    line-height: 1.5;
    padding    : 0 50px 50px;
}

em.best {
    font-style : initial;
    font-weight: 800;
}

@media screen and (max-width: 1365px) and (min-width: 768px) {
    .note p {
        padding: 20px 30px !important;
    }
}

@media screen and (min-width: 1366px) {
    .note p {
        padding: 20px 350px !important;
    }
}