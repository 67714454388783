.Gallery-grid,
.project-desk {
    display: block;
}

.Gallery-grid-mob,
.project-mob {
    display: none;
}

@media screen and (max-width: 3000px) and (min-width: 768px) {
    .mob-menu {
        display: none;
    }

    .main-body{
        width:100% !important;
    }
}




.product-range-mob,
.project-mob {
    display: none;
}

@media screen and (max-width: 926px) and (min-width: 768px) {
    #navbar li {
        /*padding: 10px 11px !important;*/
        padding: 10px 7px !important;
    }

    .welcome-col-left h1{
        padding-top:0 !important;
    }

    .welcome-col-left{
    height:auto !important;
    }

    .Home-section1 img,
    .products-container-home,
    .best-sellers-home,
    .best-sellers,
    .products-container,
    .products-container-home {
        width: 100% !important;
    }

    .pro-col1,
    .pro-col2,
    .pro-col3,
    .pro-col4,
    .pro-col5,
    .pro-col6,
    .pro-col7 {
        width: 31% !important;
    }

    .main-banner {
        height: auto !important;
    }

    .line_bt a,
    .download_btn a {
        font-size     : 16px !important;
        width         : 60% !important;
        padding       : 5px !important;
        text-transform: initial !important;
    }

    .slide-steps img,
    .slide-steps h5,
    .slide-steps p {
        margin     : 200px 0 10px 10px !important;
        width      : 55% !important;
        margin-left: 35% !important;

    }
}


@media screen and (max-width:849px) {
    .section-right img {
        margin : auto;
        display: block;
        width  : 35%;
    }

   
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
    .col2{
    display:none !important;
    }

    .welcome-4_col{
        width:33.33% !important;
        }
}

@media screen and (max-width: 925px) and (min-width: 768px) {
    #navbar li a {
        font-weight: 500 !important;
    }

    .header-left {
        width: 18% !important;
    }

    #navbar li a {
        font-size: 11px !important;
    }


    .header-caption img {

        top: 60px !important
    }

    .inner-body-container {
        width: 90% !important;
    }

    .best-sellers h1,
    .best-sellers-home h1,
    .inner_body h1 {
        font-size: 35px !important;

    }

    .formRow h4 {
        text-align: center !important;
        ;
    }

    .footer-container {
        width: 100% !important;
    }

    .formInput {
        width: 43% !important;
    }

}




@media screen and (max-width:767px) {

    .intro{
        margin-top:20px !important;
    }

    .intro h1{
        padding:100px 0 50px 0 !important;
    }
    .Home-section1-mob{
        margin-bottom:20px !important;
    }
.intro h1,
.IndicatorCarouselText h1, .welcome-col-left h1, .acu-col-right h1, .best-sellers h1, .best-sellers-home h1, .inner_body h1, .banner_body h1, .ft-col-left h1, .acu-col-right-abt h1, .acu-col-right-abt2 h1, .welcome-col h1, .inner_body-colour h1, .acu-col-top-cont h1, .acu-col-top-res h1, .welcome-col-product h1, .acu-col-top-contact h1{
    font-size:32px !important;
}

.welcome-col h3, .welcome-col-left h3, .acu-col-right h3, .ft-col-left h3, .acu-col-right-abt h3, .acu-col-right-abt2 h3, .pro-pl-col h3, .acu-col-top-cont h3, .welcome-col-product h3, .welcome-col-pro1 h3, .acu-col-top-contact h3,
li.pro-list{
    font-size:16px !important;
}

.welcome-col-left, .welcome-col, .pro-pl-col-left1, .pro-pl-col-right1, .pro-pl-col-left2, .pro-pl-col-right2{
    margin-bottom:0 !important;
}

.parallax,
.navbar-dropdown{
    display:none !important;
}

.innerBanner img{
    width: 100% !important;
    display: block;
    float: left;
    height: 200px;
    margin-top: 125px;   
}

.acu-col-left img,
.welcome-col-right img{
    padding:0 !important;
}

.welcome-container-contact{
    display:block !important;
    float:left !important;
}

.formInput2, .formInputMsg2{
    margin: 0 0 20px 20px !important; 
    width:85% !important;
}

.main-body,
.acu-col-left,
.acu-col-right-abt2,
.main-body, .acu-col-left, .acu-col-right-abt2,  .header-logo-navbar, 
.header-logo-navbar,
.main-body, .acu-col-left, .acu-col-right-abt2, .main-body, .acu-col-left, .acu-col-right-abt2,  .header-logo-navbar,  .header-logo-navbar, 
.welcome-col-pro1,
.section-container,
.pro-inner-body-container,
.section-container, .section-body,
.welcome-container-contact, .pro-pl-container,
.acu-col-contact img,
.acu-col-contact,
.formRow{
    width:100% !important;
  
}

.header-left{
    /*width:35% !important;*/
    width:100% !important;
}

.header-left img{
    width: 100% !important;
 
}
.acu-copy,
.acu-col-right-dnld h3{
    padding:20px !important;
}

.photos-gallery,
.inner_body, .inner_body-colour,
.section-right, .section-right-wht{
    margin:0 !important;
}

.product-table tr th, .product-table td{
    text-align: center !important;
}
.welcome-container2{
background: transparent !important;
}

.section-right, .section-right-wht{
    height:300px !important;
}
.section-right{
background: #123b9324 !important;
}

.thumb-container{
    margin: 0 auto 0px !important;   
}

.line-btn a, .acu-btn a, .pro-btn a{
    margin: 0 0 20px 20px !important;  
}

.welcome-copy em img{
    width:10% !important;
}
.footer-col1 img{
 width:100% !important;
 padding:0 !important;   
}

.accordion__button{
font-size:26px !important;
width:auto !important;
margin:0 20px !important;
}
.colur-name, .colur-name1{
    margin:auto !important;
}

li.resources a, li.resources-testing a, li.resources-fire a, li.resources-warranty a{
    display: block;
    padding: 15px;
    background: #113c94 !important;
    color: #fff;
    margin: 0 20px 3px 20px !important; 
    width:auto !important; 
}

    .project-mob{
        display: block !important;
    }

    .project-desk{
        display: none !important;
    }

    .range-title{
    float:initial !important;
    }

    



    .welcome-col-left, .welcome-col-right, .mpd-col-left, .mpd-col-right{
    width:100% !important;
    }
    .welcome-col-left {
        height: auto ! important;
    }

    .section-left,
    .section-right {
        margin-top: 0 !important;
    }


    .footer-col4 {
        padding-bottom: 30px !important;

    }

    .copy-right {
        font-size: 25px !important;
    }

    .GallerySection {

        background  : none !important;
        /*height    : 950px;*/
        height      : auto !important;
    }

    .welcome-copy{
    padding:20px !important;
    }

    .welcome-container,
    .welcome-container-gnt{
    display:block !important;
    float:left !important;
    }

  
    
    #navbar {
       /* display        : flex ! important;
        flex-direction : column ! important;
        align-items    : flex-start;
        justify-content: flex-start;
        margin         : auto;
        position       : fixed;
        top            : 11px;
        right          : -350px;
        width          : 250px;
        background     : #d4bc9d !important;
        height         : 100vh;*/
        align-items: flex-start;
        background: transparent !important;
        display: flex !important;
        flex-direction: column !important;
        height: 100vh;
        justify-content: flex-start;
       
        position: fixed;
        right: -350px;
        top: 11px;
        width: 250px;
    }


    #navbar.active {
        right: 0px;
    }

    #navbar li {

        margin:2px 0 0 20px !important;
       /* border-bottom: 1px dotted #fff;*/
    
    width: 75%;
    display: inline-block;
  list-style: none;
 
  padding: 15px ! important;
  text-align: center;
  background: #1b1b1bf0 !important;
  border: 1px solid #fff  ! important;
    }

    #navbar li.navbar-dropdown a{
        text-align: center !important;
    }

    #navbar li a {
        color    : #fff ! important;
        font-size: 12px ! important;

    }

    #mobile {
        display   : block ! important;
        margin-top: 40px;
    }

    #mobile i {
        font-size  : 24px;
        cursor     : pointer;
        /*padding  : 50px 0 0 70px;*/

        color: #383838 !important;
    }




    .pro-col1,
    .pro-col2,
    .pro-col3,
    .pro-col4,
    .pro-col5,
    .pro-col6,
    .pro-col7 {
        width : 92% ! important;
        margin: 0 15px 20px 15px ! important;
    }

    .col1 h5,
    .col4 h5 {
        padding   : 0px 30px 0 30px !important;
        text-align: center !important;

    }


    #navbar {
        margin-top: 100px !important;
    }


    .Gallery-grid {
        display: none;
    }

    .Gallery-grid-mob {
        display: block;
    }

    .fas.fa-bars {
        display     : block;
       
        width       : 30px;
        height      : 5px;
        margin-top  : 5px;
        background  : #383838 !important;
        margin-left : 80px;

    }

    .fas.fa-times {
        display   : block;
        /*background: #f2584d !important;*/
        width     : 30px;
        height    : 5px;
        margin-top: 5px;
        background: gray;
        ;
        margin-left: 80px;
    }



    .staff {
        background: contain ! important;
        height    : 210px ! important;
    }

    .year-logo {
        width: 25% ! important;
    }

    .inner_body p,
    .inner_body h2,
    .inner_body ul li {
        /* margin: 0 20px ! important;*/
        padding     : 0 30px;
        /*text-align: center;*/
    }

    .inner_body h2 {
        font-size     : 24px ! important;
        /* padding-top: 30px ! important;*/
        padding       : 10px 30px;

    }

    .Range-col {
        display: block !important;
    }

    .Range-col1,
    .Range-col2,
    .Range-col3 {
        display: block !important;
        margin : 0 30px !important;
    }

    .Range-col1 label,
    .Range-col2 label,
    .Range-col3 label {

        display: block;
        float  : left;
        width  : 100%;
    }




    .inner_body h5 {
        text-align : center ! important;
        line-height: 1.5;
        padding    : 0 20px;
    }

    .inner_body h4 {
        text-align: center !important;
    }

    h2.product-series {
        font-weight: 400 !important;
    }

    .Graphene-container {
        background: #fff ! important;
    }


    p.graphene-copy {
        width: auto !important;
    }


    ul.d-flex {
        display: none;
    }

    .GrapheneBanner img {
        width  : 60% ! important;
        display: block;
        float  : initial ! important;
        margin : 0 auto 40px auto !important;

    }

    .Layer_Banner img {
        width  : 92% !important;
        margin : 30px 0 !important;
        padding: 0 20px !important;
    }

    .watch-video {
        float : inherit ! important;
        width : 50% ! important;
        margin: 10px auto ! important;
    }

    iframe,
    .c-video-inn {
        height: 300px !important
    }

    .sets-icon img {
        float  : initial !important;
        margin : auto;
        width  : 12% ! important;
        padding: 0 20px 0 20px ! important;
        display: block;
    }
    
    .graphene_tit ul li{
    width:80% !important;
    }


    .sets-icon p,
    .inner_body h2 {
        text-align: center;
        ;
    }

    .section-right img {
        margin : auto;
        display: block;
        width  : 50%;
    }

    li.product-feature,
    table.product-table {
        font-size: 14px !important;
    }

    .product-description p {
        font-size: 14px !important;
    }

    table.product-table {
        width: 92% !important;
    }

    .product-note {
        padding: 20px 0 20px 30px !important;
    }

    .inner-body-container {
        width: 100% ! important;
    }

    .certificate {
        width  : 50% ! important;
        margin : 0 auto 30px auto;
        float  : initial ! important;
        display: block;
    }

    .year-logo {
        float    : right ! important;
        margin   : 30px auto ! important;
        display  : block;
        max-width: 20%;
        ;
    }

    .edge {
        width : 90% ! important;
        margin: auto !important;
        float : initial !important;
    }

    .desk-menu {
        display: none;
    }

    .mob-menu {
        display: block;
    }

    .Graphene_Banner-mob,
    .Graphene_Banner-mob img {
        display        : block ! important;
        background-size: cover;
        width          : 100%;
        height         : auto;
    }

    .header-banner-mob,
    .header-banner-mob img {
        display        : block ! important;
        background-size: cover;
        width          : 100%;
        height         : 400px;
        margin         : 20px 0;
    }

    .header-caption-mob {
        display: block ! important;
    }

    .header-caption-mob img {
        position: absolute;
        width   : 100%;
        top     : 110px;
        display : block ! important;
        height  : auto ! important;
    }

    .main-banner-mob {
        display                : block ! important;
        width                  : 100%;
        margin                 : 20px auto;
        /*height                 : 550px;*/
        height                 : 475px;
       
        background-size        : cover;
        /*background-attachment: fixed;*/
    }

    .main-banner-mob img {
        width      : 100%;
        /*padding-top: 35%;*/
        padding-top: 15%;
        display    : block;
        margin     : auto auto 30px auto;
    }

    .best-sellers h1,
    .best-sellers-home h1,
    .inner_body h1 {
        font-size: 32px !important;
    }

    .welcome-col-left h2 {
        font-size: 18px ! important;
    }

    .main-banner,
    .Home-section1,
    .Home-section1 img,
    .col2,
    .col3,
    .product-range-desk,
    .mobmenur-container,
    .header-banner,
    .Graphene_Banner {
        display: none ! important;
    }

    .colur-name,
    .colur-name1{

        position  : relative ! important;
        z-index   : inherit;
        /*margin-top: -39px ! important;*/
    }

    img.pcg-logo {
    
        width: 90% !important;
        margin: 20px auto !important;
        display: block !important;
        float:initial !important;
    }

    .colour-grid {
        display      : block ! important;
        margin-bottom: 30px ! important;
    }

    .colour-col img {
        height       : 100% ! important;
        width        : 100% ! important;
        margin-bottom: 10px;
    }

    .note p {
        padding: 20px ! important;
    }

    .col1 h1,
    .col4 h1,
    .col4 h3,
    .col4 h5 {
        text-align: center;
    }

    .col1 h1 {
        /* padding: 100px 0 0 0 ! important;*/
        padding: 35px 0 0 0 ! important;
    }

    .line_bt a,
    .download_btn a {
        margin: auto ! important;
    }

    .best-sellers-home,
    .best-sellers,
    .products-container,
    .products-container-home {
        width: 100% !important;
    }

    .Home-section1-mob,
    .Home-section1-mob img {
        display: block ! important;
        width  : 100%;
    }

    .best-sellers,
    .products-container,
    .welcome-4_col {
        width: 100% ! important;
    }

    .best-sellers h1,
    .inner_body h1 {
        font-size: 32px ! important;
    }

    .welcome-col-left,
    .welcome-col-right {
        width: 100% ! important;
    }

    .product-range-mob {
        display      : block ! important;
        margin-bottom: 30px;
    }


    .frame-style {

        height: calc(1200vh - 95px) ! important;
        width : 100% ! important;


    }

    .frame-style1 {
        height: calc(120vh - 25px) ! important;
        width : 100% ! important;
    }

    .frame-style2 {
        height: calc(300vh - 25px) ! important;
        width : 100% ! important;
    }




    .footer-container {
        display: block ! important;
        width  : 100% ! important;
    }

    .footer-col1,
    .footer-col2,
    .footer-col3,
    .footer-col4 {
        width     : 90% ! important;
        display   : block;
        text-align: center ! important;
        margin    : 30px auto ! important;
        padding   : 0 ! important;
        float     : initial ! important;
    }



    .footer-col1 {
        margin-top: 30px auto ! important;
    }

    .footer-col4 {
        margin-bottom: 35px ! important;
    }

    .footer-col1 img {
        margin    : auto;
        text-align: center;
        width     : 70% ! important;
        display   : flex;
    }

    .ft-info {
        width     : 100% ! important;
        text-align: center ! important;
        float     : initial ! important;
        margin    : 30px auto ! important;
    }

    h3.ft-col-tit {
        text-align: center ! important;
        margin    : 0 ! important;
    }

    .privacy {
        margin: 10px auto ! important;
    }

    ul li.social {
        margin: 30px 0 30px 30%;
    }

    .social a img {
        width        : 8% ! important;
        margin-bottom: 30px !important;

    }

    h2.product-feature {
        font-weight: 400 !important;
    }

 

  
    .mpd-copy{
    padding:30px !important;
    }

}

@media screen and (max-width:1400px) {
    .main-banner img {
        width      : 100% ! important;
        padding-top: 0 ! important;

    }

    .Home-section1 img {
        width: 90%;
    }

    .welcome-4_col {
        height: 320px ! important;
    }

    .welcome-col-left h1 {
       /* padding-top: 10% ! important;*/
        font-size  : 35px;
    }

    .col1 h1,
    .col4 h1 {
        /*font-size: 26px;*/
        font-size: 22px;
    }

    .welcome-col-left h2{
    font-size:18px;
    }

    .line_bt a,
    .download_btn a {
        /*width: 50% ! important;*/
        width: 60% ! important;
    }

    .col4 h1,
    .col4 h3 {
        /*padding-right: 30px ! important;*/
        padding-right: 10px ! important;
    }

  

    .col1 h1,
    .col4 h1 {
        padding: 50px 30px 0 30px !important;
    }

    .col1 h5,
    .col4 h5 {
        padding  : 0 10px 0 30px !important;
        font-size: 16px !important;
    }


}

@media screen and (max-width: 1400px) and (min-width: 1025px) {

    .slide-steps img,
    .slide-steps h5,
    .slide-steps p {
        margin: 175px 0 10px 10px ! important;
    }

    .col1 h5,
    .col4 h5 {
        padding: 0 50px 0 30px;
    }

    .col1 h1 {
        padding: 28px 0 0 30px;
    }


}


@media screen and (max-width: 1365px) and (min-width: 927px) {

    #navbar li {
        /*padding: 10px 11px !important;*/
        padding: 10px 0  !important;
    }
}


/*@media screen and (max-width: 1365px) and (min-width: 901px) {*/
    @media screen and (max-width: 1365px) and (min-width: 810px) {
    .Home-section1 img {
        width: 100% !important;
    }


    .header-logo-navbar{
        width:100% !important;
        max-width: 100% !important;
    }

    #navbar li a{
        padding:11px !important;
    }


    #navbar li{
        margin-top: 30px !important;
    }
    
    .header-right{
        text-align: center !important;
        width:60% !important
    }
    .main-banner img{
        padding-top:120px !important;
    }

    .header-right{
        float:left !important;
      
     
    }

    .Home-section1{
        margin: 0px auto auto !important;
    }
    .main-banner {
        height: auto !important;
    }

    .main-banner{
        margin:0 !important;
    }

    #navbar li a {
        font-size: 12px !important;
    }

    .section-container, .section-body,
    .header-logo-navbar{
    width:100% !important;
    }


    .best-sellers-home,
    .best-sellers,
    .products-container,
    .products-container-home {
        width: 100% !important;
    }

    .pro-col1,
    .pro-col2,
    .pro-col3,
    .pro-col4,
    .pro-col5,
    .pro-col6,
    .pro-col7 {
        width: 31.7% !important;
    }

    .col1 h1,
    .col4 h1,
    .welcome-col-left h2 {
        font-size: 20px !important;
    }

    .col1 h5,
    .col4 h5 {
        padding  : 0 25px 0 30px !important;
        font-size: 15px !important;
    }

    .line_bt a,
    .download_btn a {
        font-size: 14px !important;
        width    : 60% !important;

    }

    .slide-steps img {
        margin     : 180px 0 10px 10px !important;
        margin-left: 45% !important;
    }

    .section-left,
    .section-right {
        width: 48% !important;
    }

    .inner_body {
        width : 90% !important;
        margin: 30px auto !important;
    }

    .formInput {
        width: 44% !important;
    }

    .formInputAd,
    .formInputMsg {
        width: 94% !important;
    }

    .inner-body-container {
        width: 100% !important;
    }

    .header-caption img {

        top: 60px !important
    }

    .footer-container {
        width: 100% !important;
    }
}