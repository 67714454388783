body {
  margin: 0;

  font-family            : "Inter", sans-serif ! important;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
  color                  : #414141 !important;
}



.mk-header,
#mk-header-1,
#mk-footer,
.mk-header .mk-header-inner {
  display: none !important;
}