a {
    text-decoration: none !important;
}

.glider-slide {
    min-width: 80px !important;
}

.glider.draggable {
    overflow: hidden;
}

.glider.draggable {
    background: transparent !important;
}

.glider-slide {
    margin: 0 1px !important;
}

.glider-contain {
    width: 100% !important;
}

.glider-next {
    right: 15px !important;
    left : auto;

}



.glider-prev {
    left : 15px !important;
    right: auto;

}

.glider-next,
.glider-prev {
    top          : 40% !important;
    font-size    : 25px !important;
    background   : #ffffffb5 !important;
    border-radius: 100px !important;
    padding      : 5px 12px !important;
    color:#222 !important;
}

.thumb-container {
    display   : block;
    width     : 100%;
    margin    : 0 auto 20px;
    text-align: center;
}


.main-body{
display:block;

margin:auto;
}

.cframe,
#my-iframe{
  width:100% !important;
  height:700px !important;
}

.wpcf7-form .wpcf7-textarea{
    height:100px !important;
    max-height:100px !important;
}

.pl_note{
    display: block;
    float:left;
    margin-top: 35px;
}

@media screen and (min-width:1500px){
    .main-body{
        display:block;
        width:1147px !important;
        margin:auto;
        }
}


.glider-next.disabled,
.glider-prev.disabled {

    color: transparent !important;
}

.slide__content img {
    opacity: 0.5;
}

.--current-selected img {
    opacity: 1 !important;
}

.photos-gallery {
    margin-top: 50px;
}

em.abt {
    font-size  : 16px;
    text-align : left;
    font-weight: 800;
    font-style : normal !important;
}




.inner-container,
.inner-container-colour {
    display: block;
    width  : 100%;
    margin : 10px auto;
}


ul li.ft-col-list a {
    text-decoration: none ! important;
}

.frame-style {

    height: calc(530vh - 95px);
    width : 100% ! important;


}

.frame-style1 {
    height: calc(100vh - 25px);
    width : 100% ! important;
}

.frame-style2 {
    height: calc(200vh - 25px);
    width : 100% ! important;
}


.frame-style3 {
    height: calc(130vh - 25px);
    width : 100% ! important;
}

.header-banner {
    display: block;
    width  : 100%;
    margin : 20px auto;

    background-size: cover;
}

.header-banner img {
    width     : 100%;
    margin-top: 100px;
}


.header-banner-mob,
.header-caption-mob,
.Graphene_Banner-mob {
    display: none;
}


.inner-body-container,
.inner-body-container-colour,
.pro-inner-body-container {
    display: block;

    margin : auto;
}


.pro-inner-body-container{
    width:1147px;
    
}
.inner-body-container{
    /*width:1147px;*/
    width:100%;
}

.inner-body-container-colour{
    width:auto;
}


sup.tit_super {
    font-size: 30px ! important;

}

sup.super {
    font-size: 15px ! important;
}

.colour-grid {
    display      : flex;
    float        : left;
    width        : 100%;
    /*margin-bottom: 100px;*/
}

.colour-col img {
    display: block;
    float  : left;
    width  : 95%;
    margin-bottom:15px;

    /*height: 190px;*/
}

.colur-name,
.colur-name1 {
    display         : block;
    

    /*background      : #fff !important;*/
    padding         : 10px 0;
    text-align      : left;
    color           : #222;
    /* margin       : 130px 0 auto 20px;*/

    /*border-radius: 5px;*/
    font-size: 16px;
    font-weight: 300;
}



.colur-name {
    margin: 0 0 auto 0px;
}

.colur-name1 {
    margin: 0 0 auto 0px;
}

.note {
    display: flex;
    width  : 98%;
    margin : 30px auto;

    background: #5a95c9;

}



.copy-right {
    font-size: 30px;
}

.header-caption img {
    position: absolute;
    width   : 50%;
    top     : 150px;
}

.standard-grid {
    margin-bottom: 50px;
}

.year-logo {
    float      : right;
    margin-left: 20px;
}


.edge {
    float      : right;
    margin-left: 20px;
}

.certificate {
    width: 20%;
}


.sets-icon img {
    float: left;
    width: 6%;

    padding: 0 30px 20px 0;
}


.GrapheneBanner {
    display: block;
    float  : left;
    width  : 100%;
}

.GrapheneBanner img {
    float        : left;
    width        : 40%;
    display      : block;
    margin-bottom: 30px;
}

.inner_body,
.inner_body-colour {
    /* margin-top: 50px;*/
    margin-top: 20px;

}

.accordion__button::before{
    float:right;
}




.watch-video {
    display: block;
    float  : left;
    width  : 100%;
    margin : 10px 0;
}

.watch-video a {
    display        : block;
    padding        : 20px;
    background     : #033051 !important;
    color          : #fff;
    cursor         : pointer;
    font-weight    : 500;
    text-transform : initial;
    margin         : 0 0 10px 0;
    float          : left;
    text-decoration: none;
    ;
}

.Graphene_Banner img,
.Layer_Banner img {
    display: flex;
    width  : 100%;
    float  : left;

}

.Graphene_Banner img {
    margin: 0 0 30px 0;
}

.Layer_Banner img {
    margin: 50px 0;
}




.test-container {
    display        : flex;
    justify-content: center;
    gap            : 20px;
}


.formInput2,
.formInputMsg2{
padding: 15px 10px;
display: block;
float: left;

margin: 0 20px 20px 0;
width:100%;
}

ul {
    list-style: none !important;
  
}

.acu-col-right-abt2 ul{
    margin-top:70px;
}

li.resources a,
li.resources-testing a,
li.resources-fire a,
li.resources-warranty a{
    display: block;
    padding: 15px;
    background: #113c94 !important;
    color: #fff;
    margin: 0 auto 3px 0;
    width:100%;
  }



  li.resources a:hover,
li.resources-testing a:hover,
li.resources-fire a:hover,
li.resources-warranty a:hover{
    background: #123b93b8 !important;
    color:#fff; 
}


.parallax {
    /* The image used */
    background-image: url('./img/Images/Joondalup_HBF_Arena.webp');
  
    /* Set a specific height */
    min-height: 1200px;
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }


  .intro{
    background: #123b9324;
    display:block;
    width:100%;
  }

  .intro h1{
  text-align: center;
  font-size:40px;
  padding:50px 0;
  color:#222;
  margin:0;
  font-weight:200;
  }

  .spacer{
    display: block;
    height:100px;
    float: left;
    width:100%;
  }

  li.pro-list{
    font-size: 22px;
    padding: 0 !important;
    margin: 0 !important;
    line-height: 1.9;
    list-style-type: disc;
    list-style-position: inside;
  }

  .list-title{
    font-size: 22px;
  font-weight: 600;
  }