.section-container,
.section-body{
display:block;
float:left;
width:1147px;

padding:0;
}

.section-container{
    /*margin-top:100px;*/
    margin-top:0;
/*border-top:1px solid #ccc;-*/


}



.section-left{
width:60%;
}

.section-right,
.section-right-wht{
    width:40%;
    
    margin-top:50px;
    height:457px;
   
    }

    .section-right{
        background: #fff;
    }

    .section-right-wht{
        background: #123b9324;
    }

.section-left,
.section-right,
.section-right-wht{
display:block;

float:left;
/*margin-top:30px !important;*/
}

.section-left img{
    width:100%;
   
}

.section-right img,
.section-right-wht img{
    width:40%;
    margin-top:35px;
}

.model-name img{
    /*position:absolute;
    margin-left:10px;
    width:3%;
    margin-top:0;*/

    position: relative;
  
  width: 60%;
  margin-top: 0;
  display: block;
  float: left;
}

.section-left{
    margin-right:0;
}

.rante-flyer a{
    display: block;
    float: right;
    padding: 10px 20px;
    border: 1px solid #01294c;
    text-align: center;
    color: #01294c !important;
    text-decoration: none;
    font-size: 14px;
}

.rante-flyer a:hover{
    border: 1px solid #01294c96;
    text-align: center;
    color: #01294c96 !important;
}

li.product-feature{
list-style-type: disc;
list-style-position: outside;
font-size:16px;
}

.product-description p{
margin:30px 0;
font-size:16px;

}

h2.product-series{
margin:20px 0;
font-weight:400;
font-size:24px;
}

h2.product-feature{
margin:45px 0;
font-size:24px;
font-weight:400;
color:#222;
padding:0 30px;
}

.product-table tr th,
.product-table td{
    color:#222;
    padding:0 30px;
    text-align: left;
width:10%;

}

.product-table tr th{
    font-weight:300;
   
        padding-bottom:10px;
    
}

.product-table td{
    font-weight:300;
}

.product-note{
float:left;
font-size:14px;
}

em.star{
    font-size: 14px;
  padding: 2px 10px 0 0;
  font-style: normal;
  float: left;
}

table.product-table{
display: block;
width:100%;
text-align: left;
/*margin:20px 0 10px 10px;*/
font-size: 16px;
}

table.product-table-home{
    display: block;
    width:90%;
    text-align: center;
    margin:0 auto 10px auto;
    font-size: 16px;
    color:#0e2a4b;
    background: #0e2a4b29;
  padding: 10px 2px 2px 2px;
    }



.product-table-home tr  th{
    padding-bottom:10px;
    font-weight:600;   
    /*border-bottom:1px dotted #ffffff !important; */
}



.product-table-home td{
    text-align: center;
    width:10%;
    padding:8px 0;
}



td.model-name{
width:10%;
padding-bottom:10px;
}

.val{
display:none;
}

@media screen and (max-width:767px){
   

    .product-note{
        padding:30px 0;
    }

    .product-description p{
    padding-bottom:50px !important;
    }

    .section-right,
    .section-right-wht{
    text-align: left;
    }

    .rante-flyer a {
       float:initial !important;
        width: 30%;
        margin: 30px auto;
}


.section-left img{
    width:100%;
    height:auto !important;
   
}
}


@media screen and (max-width:849px){
    .section-left, .section-right,
    .section-right-wht{
        width: 100% ! important;
        }

      
}
