.home-container {
    display: block;
    width  : 100%;
    /*margin : 10px auto;*/
}

.main-banner {
    display: block;
    width  : 100%;
    margin : 20px auto;
    height : 780px;

    background-size        : cover;
    /*background-attachment: fixed;*/
}


.main-banner-mob,
.Home-section1-mob img,
.Home-section1-mob {
    display: none;
}

.main-banner img {
    width      : 100%;
    /*padding-top: 17% !important;*/
    display    : block;
    margin     : auto;

}

.Project-container-3col{
    display:block;
    width:1140px;
    margin:auto;
    text-align:center;
}

.Project-Container{
display:block;
width:100%;
margin:auto;
background: #0e2a4b29;
}

.welcome-col01,
.welcome-col02,
.welcome-col03{
    display:inline-block;

    width:28%;
    margin:20px;
}


.caption1{
float:right;
background: transparent;
margin-right:30px;
color:#fff;
}

.caption{
    float:left;
    background: #113c94;
    margin-left:30px;
    }

.caption,
.caption1{
display: block; 

  margin-top: -200px;
  
  z-index: 2000;
  position: relative;
  padding:0 20px;
 
}

.caption h2{
    font-size:30px;
    font-weight:500;
}

.caption h4{
    font-size:20px;
    font-weight:400;
}

.caption h2,
.caption h4{

    color:#fff;

}


.pro-col-col,
.pro-col1,
.pro-col2,
.pro-col3,
.pro-col4,
.pro-col5,
.pro-col6,
.pro-col7 {
    display     : block;
    float       : left;
    /*width     : 23%;*/
    width       : 49.5%;
    text-align  : center;
    margin:0 2px;


}

.pro-col-col {
    margin-right: 3px;
}

.pro-col-col {
    background: #0e2a4b;
    height    : 500px;
}




.pro-col1 img,
.pro-col2 img,
.pro-col3 img,
.pro-col4 img,
.pro-col5 img,
.pro-col6 img,
.pro-col7 img {

    width: 100%;
}

.project-copy{
display:block;
background:transparent;
float:left;
height:120px;
width:100%;
padding:20px 0;
}

.react-multiple-carousel__arrow--right {
    right: calc(2% + 1px) !important;
}

.react-multiple-carousel__arrow--left {
    left: calc(2% + 1px) !important;
}

.react-multiple-carousel__arrow {
    background: rgba(255, 255, 255, 0.5) !important;
}

.react-multiple-carousel__arrow::before {
color:#222 !important;
}

.resources-container{
background-color: #fff !important;
}

.resources-container,
.welcome-container,
.welcome-container-gnt,
.welcome-container-footer,
.Project-Container,
.welcome-top-container,
.banner-body-container,
.pro-pl-container,
.welcome-container-project1,
.welcome-container-project2,
.welcome-container-project3,
.welcome-container-project4,
.welcome-container-contact {
    display      : flex;
    
 
    margin       : auto;
    float        : initial;

    margin-bottom: 0;


}

.welcome-container2,
.welcome-container-abt,
.welcome-container-product{
display:inline-block;
margin:auto;
}

.resources-container,
.welcome-container,
.welcome-container-gnt,
.welcome-container-footer,
.welcome-container2,
.Project-Container,
.welcome-top-container,
.banner-body-container,
.welcome-container-abt,
.welcome-container-project1,
.welcome-container-project2,
.welcome-container-project3,
.welcome-container-project4,
.welcome-container-product{
width        : 100%;
}

.welcome-container-contact,
.pro-pl-container {
    width:1147px;   
}
.pro-pl-container2{
    display      : block;
    width        : 100%;
    margin       : auto;
    float        : initial;

    margin-bottom: 0;
}

.Project-Container,
.welcome-top-container{
background: #fff;
}

.welcome-container-abt,
.welcome-container-project1,
.welcome-container-project2,
.welcome-container-project3,
.welcome-container-project4,
.banner-body-container,
.welcome-container-product,
.welcome-container-contact{
    background: #fff;
}



.welcome-container{
    background:#113c94;
}

.welcome-container-footer {
    /*background-image: linear-gradient(#0e2a4b29, #0e2a4b29, #0e2a4b29);*/
   /* background-image: linear-gradient(#383838, #383838, #383838);*/
   background: #fff;
   margin-bottom:50px;
}

.pro-pl-container{
background:transparent;
margin-bottom:50px;
border-bottom:2px solid #123b9324;
}

.welcome-container2{
background:#123b9324;
}

.welcome-container-gnt {
    background        : #b1b1b129 !important;

}

.acu-col-left-project img,
.welcome-col-right-project img{
width:100%;
}

.welcome-col-right-project img{
    margin-left:-30px;
}

.acu-col-contact img{
    width  : 88%;
    margin-top:10px;
}

.welcome-col-right img,
.acu-col-left img,
.acu-col-left2 img {
    width  : 100%;
    
}
.acu-col-left img,
.acu-col-left-project{
    text-align: right;
    float:right;
    /*width:100%;*/
}

.welcome-col-right img,
.welcome-col-right-project img{
    float:left;
    /*width:100%;*/
}

.welcome-col-right img{
padding:115px 50px
}

.innerBanner img{
    width:100%;
}

.acu-col-left2-res img{
    width:65%;
    padding: 15px 50px;
    float:right;
}

.acu-col-left2 img{
    padding: 30px 50px;
}

.acu-col-left-project img,
.welcome-col-right-project img{
    padding:30px;
}

.acu-col-left img{
    padding: 50px 0;
}

.pro-pl-col-right1 img,
.pro-pl-col-left2 img{
    width:80%;
    display:block;
    margin:auto;
}

.pro-pl-col-btm img{
    width:100%;
    display:block;
    margin:auto;
    
}

.pro-pl-col-btm {
    margin-bottom:30px !important;
}

.welcome-col{
    display: block;
    float  : left;
    width  : 80%;
    text-align: left;
}

.acu-col-top-contact{
    display: block;
    float  : left;
    width  : 100%;
    text-align: center;
}

.acu-col-right-dnld{
    display: block;
    float  : left;
    width  : 100%;
    text-align: center;
}

.acu-col-right-dnld h3{
    padding-top:45px;
}

.ft-col-left,
.ft-col-right,
.welcome-col-left,
.welcome-col-right,
.acu-col-left,
.acu-col-right,
.acu-col-right-abt2,

.acu-col-top-cont,
.acu-col-left2,
.acu-col-top-res,
.acu-col-contact{
    display: block;
    float  : left;
    width  : 50%;
    text-align: center;

}



.pro-pl-col-left1,
.pro-pl-col-right1,
.pro-pl-col-left2{
    display: block;
    float  : left;
    width  : auto;
    text-align: center;
}
.acu-col-left-projct,
.welcome-col-right-project,
.acu-col-right-project,
.welcome-col-left-project{
    display: block;
    float  : left;
    /*width  : 100%;*/
    width:50%;
}

.pro-pl-col-top,
.pro-pl-col-btm{
    display:block;
    width:100%;
    /*margin:20px;*/
}

.acu-col-right-abt,
.acu-col-top-cont,
.acu-col-top-res{
    display: block;
    float  : initial;
    width  : 50%;
    text-align: center;
   
}

.acu-col-top-contact{
display: block;
    float  : left;
    width  : 100%;
    text-align: center;
}  
.acu-col-right-abt{
    margin:auto;
}

.acu-col-top-cont,
.acu-col-top-res,
.acu-col-top-contact{
    margin:100px auto auto;  
}

.pro-pl-col{
    display: block;
    float  : initial;
    width  : 100%;
    text-align: center;
    margin:auto;
}

.acu-col-right-pro{
    display: block;
    float  : initial;
    width  : 80%;
    text-align: center;
    margin:auto;
}

.inner_body,
.banner_body {
    display: block;
    margin:auto;
  ;
    text-align: center;
}

.banner_body{
    width  : 50%;
   background: #fff;
}

.ft-col-left,
.ft-col-right{
height:auto;
}

.welcome-copy {
    /*padding: 150px 30px 0 250px;*/
    padding-top: 50px;
}

.welcome-copy-project1,
.welcome-copy-project2,
.welcome-copy-project3,
.welcome-copy-project4{
    padding:10px;
}

.pro-pl-copy{
    padding: 0;
}

.pro-copy,
.pro-copy-intro {
    /*padding: 30px 30px 0 250px;*/
    width:100%;
    margin:auto;
    display: block;
}

.pro-copy-intro{
    text-align: center;
}

.pro-copy{
    text-align: left;
}
.acu-copy {
    padding: 10px 0 0 50px;
}
.acu-copy-abt{
    padding: 30px;
}

.acu-copy-ft {
    padding: 60px 50px 30px 250px;
}

.acu-copy-project{
padding:30px;
}

.blue-bar{
    display: block;
    float: left;
    padding: 20px;
    position: absolute;
    margin-top: -64px;
    background: #383838;
    color:#fff;
    font-weight: 500;
}

.welcome-col-left,
.welcome-col,
.pro-pl-col-left1,
.pro-pl-col-right1,
.pro-pl-col-left2,
.pro-pl-col-right2 {
  

    /*padding          : 306px 0 180px 0;*/
    background-size       : cover;
    background-position   : center;
    /*height              : 890px;*/
    /* height             : 250px;*/
    margin-bottom         : 20px;
}

.welcome-col-pro1,
.welcome-col-pro2,
.welcome-col-pro3{
    display:block;
    float:left;
}

.welcome-col-pro2 img{
padding:70px 30px;
}

.welcome-col-pro1{
    width:50%;
}
.welcome-col-pro2{
    width:20%;
}
.welcome-col-pro3{
    width:30%;
}

.welcome-4_col {
    display        : block;
    float          : left;
    /* width       : 50%;*/
    width          : 25%;
    background     : #05b4c9;
    text-align     : center;
    /*padding      : 195px 0;*/
    height         : 445px;
}

.col1 {
    background: #0e2a4b;
}

.col2 {
   
    background-size: cover;
}

.col3 {
   
    background-size: cover;
}

.col4 {
    background: #05b4c9;
}

.welcome-copy em img{
    width: 5%;
  padding: 0 20px 0 0;
}

.pro-line-btn a{
    display       : block;
   
    padding       : 15px;
   
    text-align    : center;
    color         : #fff;
    text-transform: uppercase;
    background:#113c94;
    width:30%;
    margin:auto;
    font-size: 14px !important;
    font-weight: 500;
}

.pro-line-btn a:hover{
    background:#123b93b8;
  
}


.line-btn a,
.acu-btn a,
.pro-btn a{
    display       : block;
    float         : left;
    padding       : 20px 30px;
    border        : 1px solid #113c94;
    text-align    : center;
    color         : #222;
    text-transform: uppercase;

}

.line-btn a,
.pro-btn a{
    border        : 1px solid #113c94;
    background:#113c94;
    color:#fff;
}

.line-btn a:hover,
.pro-btn a:hover{
border: 1px solid #2c60ce !important;
background:#2c60ce;
}

.pro-btn a {
    margin: 30px 0 0 30px;
}

.line-btn a {
    margin: 0;
}

.acu-btn a {
    margin: 30px 250px 0 30px;
}

.home-caption img {
    display     : block;
    text-align  : center;
    margin-top  : -500px;
    margin-left : auto;
    margin-right: auto;

    color   : #fff;
    position: relative;
}

.Home-section1 {

    background: transparent;
    display   : block;
    /*margin    : 180px auto auto;*/
    margin    : 112px auto auto;
    opacity   : .8;
    position  : relative;
    z-index   : 200;
}

.main-banner video {
    position : relative;
    max-width: 100%;
    width    : 100% !important;
}

.Home-section1 img {
    height : auto;
    margin : auto;
    display: block;
    width  : 1147px;
}

.best-sellers-home,
.best-sellers,
.products-container,
.products-container-home {

    width           : 100%;
    display         : block;
    margin          : 2px auto;
   /* height          : 500px;*/
   /* background-image: linear-gradient(#0e2a4b29, white, #0e2a4b29);*/

}

.best-sellers,
.products-container {
    float: left;
}

.line_bt a,
.download_btn a {
    display       : block;
    border        : 1px solid #fff;
    padding       : 10px;
    color         : #fff;
    text-transform: uppercase;
    text-align    : ce;
    ;

    text-decoration: none;
    margin-left    : 30px;
}


.line_bt a {
    width: 30%;
}

.download_btn a {
    width: 40%;
}

.GallerySection {
    display     : block;
    width       : 100%;

    /*height    : 950px;*/
    height      : 899px;
}

.IndicatorCarousel img,
.IndicatorCarouselHome img {
    display: block;
    height : auto;
    float  : initial;
    margin : auto !important;
 
}


.IndicatorCarousel img {
    width  : 70%;
}


.IndicatorCarouselHome img {
    width  : 100%;
}

.IndicatorCarousel,
.IndicatorCarouselHome {
    display: block;
    float  : initial;
    width  : 100%;

}

.Gal-info {
    display     : block;
    width       : 70%;
    /*background: #d8dde2;*/
    background  : #fff;
    margin      : auto;
    text-align  : left;
    padding-bottom   : 20px;
}


.Gal-info img {
    width       : 30%;
    height      : auto;
    float       : right;
    display     : block;
    padding-left: 5px;
    margin-top:-25px !important;
}

.more a {
    display       : block;
    margin        : 15px auto;
    width         : 20%;
    float         : initial;
    padding       : 20px 30px;
    text-align    : center;
    border        : 1px solid #fff;
    color         : #fff !important;
    text-transform: uppercase;
    background    : #0e2a4b;
}

.accordion {

    border-radius: 2px;
    display: block;
    width: 100%;
    float: left;
    margin: auto;
    background: #fff;
    border:none !important;
}

.accordion__button{
background:transparent !important;
border-bottom: 1px solid #222 !important;
}

.accordion__button{
color:#222 !important;
font-size:36px;
font-weight: 200;
}

.acu-col-right-pro{
    color:#fff !important;
    font-size:18px;
    text-align: left !important;
}

.col-border img{
    border:1px solid #222;
}
img.react-parallax-bgimage{
    position: absolute !important;
  left: 50%;
  transform: translate3d(-50, -132.28px, 0px) !important;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  height: auto !important;
  width: auto;
}

.react-parallax{
    margin-bottom: 0 !important;
}

.img-caption h1{
    color:#fff !important;
    text-align: center !important;
    padding-top:100px;
}

.project-info-box1,
.project-info-box2{
    display: block;
  background: #222;
  padding: 30px;
  margin-top: 390px;
  margin-bottom: 50px;
  width: 75%;


}

.project-info-box1{
    margin-left: -70px;
}



.project-info-box2{
left:240px;
position:relative;

}

.blank-space{
padding-top:150px;
}