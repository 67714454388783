#mobile {
    display: none;
}

#navbar {
    display: block;
    padding: 20px 10px;
    margin : auto;
}



#navbar li {
    list-style: none;
    display   : inline-block;
    
    text-align: center;
    margin-top: 42px;
}

#navbar li a {
    text-decoration: none;
    text-transform : uppercase;
    color          : #222;
    font-size      : 16px;
    font-weight    : 600;
    padding   : 11px 35px;
}

#navbar li a:hover{
background:#123b9324;
color:#113c94 !important;

}

#navbar li.phone a:hover{
    background:transparent !important;
}

#navbar li a.active{
    background:#113c94 !important;

color: #fff !important;
}

#navbar li.quote,
#navbar li.quote a {
    background: #f2584d !important;
    color     : #fff ! important;
}



.szh-menu-button {
    box-sizing    : border-box;
    border        : 0;
    background    : none;
    font-size     : 14px;
    font-size     : 16p;
    font-weight   : 600;
    text-transform: uppercase;
    font-family   : 'Montserrat', sans-serif ! important;
    color         : #222;
    cursor        : pointer;
    margin        : 0 !important;

}


.submenu-item a {
    display   : block !important;
    float     : left;
    padding   : 0px;
    text-align: center;
    margin    : 0 !important;
}

.szh-menu {
    float  : left !important;
    width  : auto !important;
    display: block;
    margin        : 0 !important;

}


.phone a{ 
font-size:24px !important;
color:#113c94 !important;
}


li {
    position: relative;
}


ul li ul {
    background:#dee3f0;
	visibility: hidden;
  opacity: 0;
  min-width: 5rem;
	position: absolute;
  transition: all 0.5s ease;
  margin-top: 12px;
	left: 0;
  display: none;
  width:200px;
  height:165px;
 
}

ul li:hover > ul,
ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}

ul li ul li {
	clear: both;
  width: 100%;
}

li.sub a{
    
  text-align: left;
  background: transparent !important;
  border-bottom: 1px solid #fff;
  padding: 2px 20px !important;

  background:#dee3f0;
color:#222 !important;
}

li.sub a:hover{
   
    color:#113c94 !important;
    padding:20px;
}


nav {
    width: 100%;
    height: 20px;
    margin-top:45px;
  }
  li.desk-menu a:hover {
    color: #007aff !important;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;

  }
  
  li.desk-menu a.active {
    background-color: #113c94;
    color: #fff !important;
    padding: 10px 12px;
  }

  /* Dropdown */

 #navbar li.navbar-dropdown {
    position: relative;
  }
  
  #navbar li.navbar-dropdown:hover .dropdown {
    visibility: visible;
    opacity   : 1;
    transform : translateY(0px);
  }
  
  #navbar li.navbar-dropdown .dropdown {
    visibility                : hidden;
    opacity                   : 0;
    position                  : absolute;
    padding                   : 20px;
    top                       : 100%;
    transform                 : translateY(50px);
    left                      : 0;
    width                     : 215px;
    background-color          : #ffffffe5;
    box-shadow                : 0px 10px 10px 3px rgba(0, 0, 0, 0.3);
    border-bottom-left-radius : 3px;
    border-bottom-right-radius: 3px;
    z-index                   : 111;
    transition                : 0.4s all;
  }
  
  #navbar li.navbar-dropdown .dropdown a {
    padding-top   : 10px;
    padding-bottom: 10px;
    font-weight   : 400;
    border-bottom : 1px dotted #222;
  
  }
  
  #navbar li.navbar-dropdown .dropdown a:hover {
    padding-left: 5px;
    color       : #000 ! important;
  }

  
  #navbar li.navbar-dropdown a {
    display        : block;
    text-decoration: none;
    color          : #222;
    /*padding        : 11px 20px;*/
    font-weight    : 500;
    transition     : 0.4s all;
    text-align: left;
  }
  


  .dropdown h3{
    text-align: left;
  }