form {
 /* margin-top: 35px;*/
 margin-top:0;
}

.formRow {
  margin: 10px 0 20px 0;
  width:85%;
}

.Range-col {
  display: flex;
  width  : 100%;
  margin : 20px auto;
}

.Range-col1,
.Range-col2,
.Range-col3 {
  display: grid;

  width : 100%;
  margin: 0 auto 30px auto;
}

.inner_body h5 {
  margin-top: 0 !important;
}

h5.form-title {
  padding-top: 20px;
}

.blank {
  color: transparent !important;
}

.range-title {
  display: block;
  float  : left;

}

.Range-col1 label,
.Range-col2 label,
.Range-col3 label {
  line-height: 1.8 !important;
}


.formInput,
.formInputAd,
.formInputMsg {
  padding: 15px 10px;
  display: block;

  float : left;
  /*margin: 20px 20px 20px 0;*/
  margin: 0 20px 20px 0;
}

.formInputAd,
.formInputMsg {
  /*width: 96%;*/
  width: 87%;
}

.button{
  list-style: none;
  display: block;
  padding: 15px;
  text-align: center;
 text-transform: uppercase;
  border-radius: 0;
  background: #000 !important;
  color: #fff !important;
  font-family: "Inter", sans-serif !important;
  font-size: 16px;

  margin:auto;
  border:0;
}

wpcf7-submit,
.step_2 {
  padding         : 20px !important;
  font-size       : 22px;
  background-color: #e05b45 !important;

  margin        : 20px auto !important;
  border        : 0 !important;
  color         : #fff !important;
  text-transform: uppercase;
  cursor        : pointer;

}


.formRow h4 {
  margin : 30px 0 0 0 !important;
  padding: 20px 0 20px 0;
}

.formInput {
  width: 40%;
}

.errorMessage {
  color    : #f45532;
  font-size: 14px;
  margin   : 0;
  padding  : 0;
}

.submit-btn {
  padding         : 15px 50px;
  border-radius   : 0;
  background-color: #e05b45 !important;
  margin          : 20px auto !important;
  border          : 0 !important;
  color           : #fff !important;
  display         : block;
  text-transform  : uppercase;
  cursor          : pointer;
  font-family     : 'Montserrat', sans-serif !important;
  font-size       : 16px;
  font-weight     : 600;
}



@media (max-width: 768px) {
  .submit-message {
    width      : 125px;
    margin-left: 200px;
  }

  .formInput,
  .formInputAd,
  .formInputMsg {
    display: block;
    width  : 90%;
    margin : 10px auto !important;
    float  : initial !important;
  }
}